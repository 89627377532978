<template>
  <div class="prefooter" ref="prefooter">
    <loading
      :active.sync="isLoading"
      :can-cancel="false"
      color="#E30713"
      :height="145"
      :width="145"
      :opacity="0.7"
      backgroundColor="#eeeeee"
      :z-index="3"
      :is-full-page="true"
    ></loading>
    <div class="container">
      <div class="buttons" :class="{ hovered: isHover }">
        <div @click="savePdf" class="buttons__item buttons__item-download">
          <inline-svg :src="require('@/assets/img/footer/pdf.svg')" />
          <div class="title">
            {{ $t('message.preFooter.pdf') }}
          </div>
        </div>
        <div @click="saveXls" class="buttons__item buttons__item-download">
          <inline-svg :src="require('@/assets/img/footer/xls.svg')" />
          <div class="title">
            {{ $t('message.preFooter.xls') }}
          </div>
        </div>
        <div @click="print" class="buttons__item buttons__item-download">
          <inline-svg :src="require('@/assets/img/footer/print.svg')" />
          <div class="title">
            {{ $t('message.preFooter.print') }}
          </div>
        </div>
        <div
          class="buttons__item buttons__item-save"
          @mouseover="isHover = !isHover"
          @mouseleave="isHover = !isHover"
          @click="goToSavePage"
        >
          <div class="img">
            <img src="@/assets/img/icons/cloud.svg" alt="save" />
          </div>
          <div class="content">
            <div class="content__title">
              {{ $t('message.preFooter.save') }}
            </div>
            <div class="content__text">
              {{ $t('message.preFooter.saveText') }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <modal
      :is-modal-open="isModalOpen"
      bg="grey"
      :is-show-close="false"
      :radius="10"
      @close="closeModal"
    >
      <template #body>
        <save-calculation-variants
          @close="closeModal"
          @updateCalculation="updateCalculation"
          @copyCalculation="copyCalculation"
        />
      </template>
    </modal>
  </div>
</template>

<script>
import InlineSvg from 'vue-inline-svg'
import { mapState, mapGetters } from 'vuex'
import {
  checkIsCalculationBelongsToUser,
  copyCalculation,
  saveExtendedCalculation,
  updateCalculation
} from '@/api/moderator'
import SaveCalculationVariants from '@/components/dump/SaveCalculationVariants'
import { handleError } from '@/utils/deepSettings'
// import axios from 'axios';

export default {
  props: {
    isInDetails: Boolean,
    isAuth: Boolean
  },
  data: () => ({
    isHover: false,
    isModalOpen: false,
    isLoading: false
  }),
  methods: {
    justSave() {
      this.isLoading = true
      const { userId, sectors, result, $i18n } = this
      const lang = $i18n.locale
      saveExtendedCalculation({
        lang,
        userId,
        sectors,
        result
      })
        .then(response => {
          const hash = response.data
          // console.log(hash)
          this.isLoading = false
          this.$router.push({ name: 'DeepSettings', params: { lang, hash } })
        })
        .catch(e => {
          handleError(this.$notify, e)
          this.isLoading = false
        })

      // this.saveResultExtended({
      //   lang: this.$i18n.locale
      // })
    },
    extendedSave() {
      if (this.currentHash) {
        this.isLoading = true
        checkIsCalculationBelongsToUser(this.userId, this.currentHash).then(response => {
          if (response.data) {
            this.isModalOpen = true
            this.isLoading = false
          } else {
            this.justSave()
          }
        })
      } else {
        this.justSave()
      }
    },
    goToSavePage() {
      if (this.isAuth) {
        this.extendedSave()
      } else {
        this.$router.push(`/${this.$i18n.locale}/save`)
      }
    },
    closeModal() {
      this.isModalOpen = false
    },
    print() {
      window.print()
    },
    savePdf() {
      this.$emit('savePdf')
    },
    saveXls() {
      this.$emit('saveXls')
    },
    updateCalculation(variant) {
      const { $i18n, userId, sectors, result, currentHash: hash, getAllDeepSettings } = this
      const lang = $i18n.locale
      updateCalculation({
        lang,
        userId,
        sectors,
        result,
        deepSettings: { ...getAllDeepSettings },
        action: variant,
        hash
      })
        .then(response => {
          this.isLoading = false
          this.closeModal()
          if (response.data) {
            this.$router.push({ name: 'DeepSettings', params: { lang, hash } })
          } else {
            this.isLoading = false
            handleError(this.$notify)
          }
        })
        .catch(e => {
          this.isLoading = false
          this.closeModal()
          handleError(this.$notify, e)
        })
    },
    copyCalculation(variant) {
      this.isLoading = true
      const { $i18n, userId, currentHash: hash } = this
      const lang = $i18n.locale
      copyCalculation({
        lang,
        userId,
        hash,
        action: variant
      })
        .then(response => {
          this.isLoading = false
          this.closeModal()
          const hash = response.data
          this.$router.push({ name: 'DeepSettings', params: { lang, hash } })
        })
        .catch(e => {
          this.isLoading = false
          this.closeModal()
          handleError(this.$notify, e)
        })
    }
  },
  components: {
    SaveCalculationVariants,
    Modal: () => import('@/components/elements/Modals/Modal'),
    InlineSvg
  },
  computed: {
    ...mapState('moderator', {
      currentHash: state => state.currentHash,
      userId: state => state.userId
    }),
    ...mapState({
      sectors: state => state.sectors,
      result: state => state.result
    }),
    ...mapGetters('moderator', {
      getAllDeepSettings: 'getAllDeepSettings'
    })
  }
}
</script>
<style lang="sass">
.prefooter
  .buttons
    &__item
      &-download
        svg
          path
            transition: .5s
        &:hover
          background: $red
          color: #fff
          .title
            color: #fff
          svg
            path
              fill: #fff
</style>
<style lang="sass" scoped>
.prefooter
  background: #fff
  +media((margin-top: (320: rem(48), 960: rem(48))))
.buttons
  position: relative
  display: grid
  +media((grid-template-columns: ((320: repeat(3, 1fr), 960: 5fr 5fr 5fr 9fr))))
  &:after
    content: ""
    height: 100%
    width: calc((100vw - 100%) /2 )
    right: calc(((100vw - 100%) /2) * -1 )
    top: 0
    background: $red
    position: absolute
    transition: .5s
  &.hovered
    &:after
      background: $red-hover
  &__item
    &-download
      color: $black
      background: #fff
      box-shadow: inset rem(-1) 0 0 #ECECEC
      display: flex
      flex-direction: column
      justify-content: center
      align-items: center
      transition: .5s
      cursor: pointer
      text-decoration: none
      // +media((width: (320: calc(100% / 3), 960: rem(200))))
      +media((height: (320: rem(144), 960: rem(170))))
      .title
        font-style: normal
        font-weight: 500
        text-align: center
        transition: .5s
        +media((padding: (320: rem(19) 0 0, 960: rem(26) 0 0)))
        +media((max-width: (320: rem(78), 960: unset)))
        @extend .fs-14
        +media((font-size: (320: rem(12), 960: rem(14))))
      &:hover
        background: $red
        color: #fff
        .title
          color: #fff
    &-save
      background: $red
      display: flex
      flex-direction: row
      align-items: center
      max-width: rem(360)
      text-decoration: none
      transition: .5s
      position: relative
      cursor: pointer
      margin-left: auto
      box-sizing: border-box
      +media((padding: (320: rem(28) rem(24) rem(34), 960: 0)))
      +media((max-width: (320: unset, 960: rem(360))))
      +media((width: (320: 100%, 960: auto)))
      +media((grid-area: (320: '2 / 1 / 3 / 4', 960: '1 / 4 / 2 / 5')))
      &:hover
        background: $red-hover
      .img
        pointer-events: none
        +media((padding: (320: 0 rem(24) 0 0, 960: 0 rem(33))))
      .content
        color: #fff
        text-align: left
        pointer-events: none
        &__title
          font-style: normal
          font-weight: bold
          padding-bottom: rem(14)
          +media((font-size: (320: rem(20), 960: rem(24))))
          +media((line-height: (320: rem(24), 960: rem(29))))
        &__text
          font-style: normal
          font-weight: 600
          font-size: rem(12)
          line-height: rem(20)
</style>
