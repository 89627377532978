<template>
  <div class="save-calculation">
    <div class="save-calculation__head">
      <form-title> Сохранить как... </form-title>
      <app-button transparent size="small" @click="close">
        <template #icon>
          <inline-svg :src="require('@/assets/img/icons/dashboard/close.svg')" />
        </template>
      </app-button>
    </div>
    <div class="save-calculation__variants">
      <div
        class="save-calculation__variants-item"
        v-for="variant in saveVariants"
        :key="variant.id"
        @click="handle(variant.method)"
      >
        <div class="picture">
          <img :src="variant.icon" :alt="variant.method" />
        </div>
        <div class="text">
          {{ variant.text }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import InlineSvg from 'vue-inline-svg'
import FormTitle from '@/components/smart/DeepSettings/FormTitle'
import AppButton from '@/components/elements/AppButton'
const update = 'update'
const saveVariants = [
  {
    id: 0,
    icon: require('@/assets/img/icons/save/update.svg'),
    text: 'Обновить текущую версию расчета',
    method: update
  },
  {
    id: 1,
    icon: require('@/assets/img/icons/save/correction.svg'),
    text: 'Создать отдельную версию расчета как корректировка',
    method: 'correction'
  },
  {
    id: 2,
    icon: require('@/assets/img/icons/save/error.svg'),
    text: 'Создать отдельную версию расчета как ошибка',
    method: 'error'
  }
]
export default {
  name: 'SaveCalculationVariants',
  components: { AppButton, FormTitle, InlineSvg },
  computed: {
    saveVariants: () => saveVariants
  },
  methods: {
    handle(variant) {
      if (variant === update) {
        this.$emit('updateCalculation', variant)
      } else {
        this.$emit('copyCalculation', variant)
      }
    },
    close() {
      this.$emit('close')
    }
  }
}
</script>

<style scoped lang="sass">
.save-calculation
  max-width: rem(960)
  padding: rem(68) rem(80) rem(64)
  border-radius: rem(10)
  box-sizing: border-box
  &__head
    display: flex
    align-items: center
    justify-content: space-between
    margin-bottom: rem(60)
    .form-title
      margin: 0
  &__variants
    display: grid
    grid-template-columns: repeat(3, 1fr)
    gap: rem(24)
    &-item
      background: $white
      border-radius: rem(10)
      cursor: pointer
      display: flex
      flex-direction: column
      justify-content: center
      align-items: center
      box-sizing: border-box
      padding: rem(30)
      transition: .3s
      @media (any-hover: hover)
        &:hover
          box-shadow: 0 rem(2) rem(20) rgba($black_C, .35)
          .picture
            opacity: 1
      .picture
        line-height: 0
        margin-bottom: rem(24)
        opacity: .75
        transition: .3s
      .text
        @extend %H418R
        color: $light-black
        text-align: center
</style>
